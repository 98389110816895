@import '../../../libs/scss/index.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
dashboard-root {
    @apply h-full text-dark-max antialiased;
}

dashboard-root {
    @apply block;
}
