/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */
:root {
	--text-colors-text-subtle: rgba(51, 51, 51, 0.85);
	--text-colors-text-dark-dark-max: #333;
	--text-colors-text-dark-dark-primary: rgba(51, 51, 51, 0.85);
	--text-colors-text-dark-dark-secondary: rgba(51, 51, 51, 0.75);
	--text-colors-text-dark-dark-tertiary: rgba(51, 51, 51, 0.65);
	--text-colors-text-light-light-max: #fff;
	--text-colors-text-light-light-primary: rgba(255, 255, 255, 0.85);
	--text-colors-text-light-light-secondary: rgba(255, 255, 255, 0.75);
	--text-colors-text-light-light-tertiary: rgba(255, 255, 255, 0.65);
	--grey-grey-50: #f7f7f7;
	--grey-grey-100: #eee;
	--grey-grey-200: #e3e3e3;
	--grey-grey-300: #d1d1d1;
	--grey-grey-400: #acacac;
	--grey-grey-500: #8b8b8b;
	--grey-grey-600: #646464;
	--grey-grey-700: #515151;
	--grey-grey-800: #333;
	--grey-grey-900: #131313;
	--brand-color-purple-purple-50: #eee8f0;
	--brand-color-purple-purple-100: #c9b8cf;
	--brand-color-purple-purple-200: #af96b8;
	--brand-color-purple-purple-300: #8a6698;
	--brand-color-purple-purple-400: #734984;
	--brand-color-purple-purple-500: #501b65;
	--brand-color-purple-purple-500-06: #501b650f;
	--brand-color-purple-purple-500-07: #501b6512;
	--brand-color-purple-purple-600: #49195c;
	--brand-color-purple-purple-700: #391348;
	--brand-color-purple-purple-800: #2c0f38;
	--brand-color-purple-purple-900: #220b2a;
	--brand-color-purple-purple-900-07: #220b2a12;
	--brand-color-yellow-yellow-50: #fff8e6;
	--brand-color-yellow-yellow-100: #fde9b0;
	--brand-color-yellow-yellow-200: #fdde8a;
	--brand-color-yellow-yellow-300: #fccf54;
	--brand-color-yellow-yellow-400: #fbc533;
	--brand-color-yellow-yellow-500: #fab700;
	--brand-color-yellow-yellow-600: #e4a700;
	--brand-color-yellow-yellow-700: #b28200;
	--brand-color-yellow-yellow-800: #8a6500;
	--brand-color-yellow-yellow-900: #694d00;
	--brand-color-mint-mint-50: #f6fbfc;
	--brand-color-mint-mint-100: #e3f3f4;
	--brand-color-mint-mint-200: #d6edef;
	--brand-color-mint-mint-300: #c3e5e8;
	--brand-color-mint-mint-400: #b7e0e3;
	--brand-color-mint-mint-500: #a5d8dc;
	--brand-color-mint-mint-500-20: #a5d8dc33;
	--brand-color-mint-mint-600: #96c5c8;
	--brand-color-mint-mint-700: #75999c;
	--brand-color-mint-mint-800: #5b7779;
	--brand-color-mint-mint-900: #455b5c;
	--brand-color-blue-blue-50: #e6f0f7;
	--brand-color-blue-blue-100: #b0d1e7;
	--brand-color-blue-blue-200: #8abbdc;
	--brand-color-blue-blue-300: #549ccc;
	--brand-color-blue-blue-400: #3389c2;
	--brand-color-blue-blue-500: #006bb3;
	--brand-color-blue-blue-600: #0061a3;
	--brand-color-blue-blue-700: #004c7f;
	--brand-color-blue-blue-800: #003b62;
	--brand-color-blue-blue-900: #002d4b;
	--feedback-colors-error-500: #eb0000;
	--feedback-colors-error-700: #a70000;
	--feedback-colors-info-500: #006bb3;
	--feedback-colors-success-500-10: #96dbab1a;
	--feedback-colors-success-500: #96dbab;
	--feedback-colors-warning-500: #fbb33c;
	--feedback-colors-warning-500-10: #fbb33c1a;
	--white: #fff;
}
